html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  width: 100%;
}

pre {
  font-family: inherit !important;
}

*,
*::before,
::after {
  word-wrap: normal !important;
}

*:focus {
  outline: none !important;
  box-shadow: none !important;
}
/* Checkbox */
*[data-focus] {
  box-shadow: none !important;
}

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

div[id*='react-select'] {
  overflow: visible !important;
}

#modalContainer > div > div > div {
  overflow: visible;
}

#modalContainer > div > div::-webkit-scrollbar {
  display: none;
}

.botmd-sidebar {
  overflow: scroll !important;
}

.botmd-sidebar::-webkit-scrollbar {
  display: none;
}

.workspace__menu-list {
  overflow: auto !important;
}

.scrollbar-invisible::-webkit-scrollbar {
  display: none;
}
iframe {
  pointer-events: none;
}

td {
  min-height: 70px;
}

@media print {
  html,
  body {
    height: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
  #header,
  #footer,
  #nav,
  #graphViewDropdown,
  #downloadDataBtn,
  #backBtn,
  #patientShowTabs,
  #dateRangeNavRight,
  #dateRangeNavLeft {
    display: none !important;
  }
  .graph {
    page-break-after: always;
  }
  #graphContainer {
    transform: scale(1) !important;
    margin-top: 0px !important;
  }
  table {
    page-break-after: always;
    table-layout: fixed;
    page-break-inside: avoid;
  }

  tr {
    height: 30px;
    break-before: avoid;
  }

  /* thead#hide-print {
    display: none;
  }*/

  #patientProfileContainer {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  #appContent {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: 0px !important;
  }

  #export_data_trigger_element_btn_default {
    display: none;
  }

  table th {
    max-width: 140px;
    overflow-wrap: break-word;
    word-wrap: normal;
  }

  tr[id*='table_row_data-submission'] {
    display: table-row-group;
  }

  /* div.sc-bcXHqe.giBFGZ {
    padding-left: 0px;
    margin-left: -10px;
    padding-right: 16px;
  } */

  thead {
    display: inline-table;
  }

  th[id*='timestamp'] {
    padding-right: 85px;
  }
}

@media screen and (max-width: 375px) {
  .patient-submissions {
    width: 49vh !important;
  }
}
@media screen and (max-width: 360px) {
  .patient-submissions {
    width: 39vh !important;
  }
}

::-webkit-scrollbar {
  width: 12px;
  box-shadow: none;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: #797c7e;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: #797c7e;
}
